import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import Quasar from 'quasar/src/vue-plugin.js';import QSpinnerPuff from 'quasar/src/components/spinner/QSpinnerPuff.js';;
import quasarUserOptions from './quasar-user-options';
import { EnfisQuasar } from '@enfis/quasar';

createApp(App)
    .use(Quasar, quasarUserOptions)
    .use(i18n)
    .use(store)
    .use(router)
    .use(EnfisQuasar, {
        config: {
            io: { baseURL: window.config.enfisBaseUrl },
            loading: {
                spinner: QSpinnerPuff,
                spinnerColor: 'blue-6',
                spinnerSize: 100,
            },
            props: {
                tinyEditorOptions: {
                    height: '500px',
                    menubar: false,
                    toolbar_mode: 'sliding',
                    font_size_formats: '8px 10px 12px 14px 16px 24px 48px',
                    content_style: '.mce-content-body{ font-family:"Open Sans", sans-serif; margin: 1rem 0; } ',
                    plugins:
                        'advlist anchor autolink code directionality emoticons charmap image insertdatetime link lists media nonbreaking pagebreak preview searchreplace table visualblocks visualchars wordcount',
                    toolbar: [
                        { name: 'history', items: ['undo', 'redo'] },
                        { name: 'blocks', items: ['blocks'] },
                        { name: 'fontsize', items: ['fontsize'] },
                        { name: 'formatting', items: ['bold', 'italic', 'underline'] },
                        { name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'] },
                        { name: 'indentation', items: ['bullist', 'numlist', 'outdent', 'indent'] },
                        { name: 'color ', items: ['forecolor', 'backcolor'] },
                        { name: 'src ', items: ['link'] },
                        { name: 'searchreplace ', items: ['searchreplace'] },
                    ],
                },
            },
        },
    })
    .mount('#app');
