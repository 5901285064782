<template>
    <p class="q-ma-lg">Už čoskoro...</p>
</template>

<script>
export default {
    components: {},
    name: 'MTView',
    data() {
        return {};
    },
    methods: {},
    computed: {},
    mounted() {},
    beforeMount() {},
};
</script>
