<template>
    <q-layout
        view="hHh lpR fFf"
        container
    >
        <q-page-container>
            <div class="bg"></div>
            <q-page
                class="flex flex-center scroll"
                :style-fn="$e.utils.pageStyleFn"
            >
                <q-dialog
                    v-model="forgetPasswd.dialog"
                    no-backdrop-dismiss
                >
                    <q-card>
                        <q-toolbar>
                            <q-toolbar-title>Zabudnuté heslo</q-toolbar-title>
                        </q-toolbar>
                        <q-form
                            ref="forgetPasswordForm"
                            @submit="onSubmitForgetPasswordEmail"
                        >
                            <q-card-section class="scroll">
                                <p>Zadajte email vášho konta:</p>
                                <div class="row q-col-gutter-md">
                                    <div class="col-12">
                                        <q-input
                                            v-model="forgetPasswd.mail"
                                            outlined
                                            stack-label
                                            label="Email"
                                            hide-bottom-space
                                            clearable
                                            lazy-rules="ondemand"
                                            :rules="$e.form.rules({ required: { required: true }, email: { multiple: false } })"
                                        >
                                            <template v-slot:prepend> <q-icon name="mail" /> </template
                                        ></q-input>
                                    </div>
                                </div>
                            </q-card-section>
                            <q-card-actions align="right">
                                <CButton
                                    label="Odoslať"
                                    icon="done"
                                    color="positive"
                                    type="submit"
                                />
                                <CButton
                                    outline
                                    label="Zavrieť"
                                    color="primary"
                                    v-close-popup
                                />
                            </q-card-actions>
                        </q-form>
                    </q-card>
                </q-dialog>

                <q-dialog
                    v-model="createUser.dialog"
                    no-backdrop-dismiss
                >
                    <q-card>
                        <q-toolbar>
                            <q-toolbar-title>Registrácia</q-toolbar-title>
                        </q-toolbar>
                        <q-form
                            ref="createUserForm"
                            greedy
                            no-error-focus
                            no-reset-focus
                            enctype="multipart/form-data"
                            @submit="onSubmitCreateUserForm"
                            @reset="onResetCreateUserForm"
                            @validation-success="onValidationSuccessCreateUserForm"
                            @validation-error="onValidationErrorCreateUserForm"
                        >
                            <q-card-section class="scroll">
                                <div class="itemsGroup">
                                    <div class="row q-col-gutter-md">
                                        <div class="col-12">
                                            <div class="groupTitle">
                                                <div class="text-h6">Základné údaje</div>
                                                <div>Tieto údaje je potrebné vyplniť, aby sme vás mohli zaregistrovať</div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <q-input
                                                v-model="createUser.firstName"
                                                name="user-first_name"
                                                outlined
                                                stack-label
                                                label="Meno *"
                                                hide-bottom-space
                                                clearable
                                                :lazy-rules="validationErrorCreateUserForm ? false : 'ondemand'"
                                                :rules="$e.form.rules({ required: true, length: { max: 500 } })"
                                                @blur="fillLogin()"
                                            />
                                        </div>
                                        <div class="col-12">
                                            <q-input
                                                v-model="createUser.lastName"
                                                name="user-last_name"
                                                outlined
                                                stack-label
                                                label="Priezvisko *"
                                                hide-bottom-space
                                                clearable
                                                :lazy-rules="validationErrorCreateUserForm ? false : 'ondemand'"
                                                :rules="$e.form.rules({ required: true, length: { max: 500 } })"
                                                @blur="fillLogin()"
                                            />
                                        </div>
                                        <div class="col-12">
                                            <q-input
                                                v-model="createUser.mail"
                                                name="user-mail"
                                                outlined
                                                stack-label
                                                label="Email *"
                                                hide-bottom-space
                                                clearable
                                                :lazy-rules="validationErrorCreateUserForm ? false : 'ondemand'"
                                                :rules="$e.form.rules({ required: { required: true }, email: { multiple: false } })"
                                            />
                                        </div>
                                        <div
                                            class="col-12 q-mt-md"
                                            v-if="createUser.showLogin"
                                        >
                                            <p>Zvolte si prihlasovacie meno</p>
                                            <q-input
                                                v-model="createUser.login"
                                                name="user-login"
                                                outlined
                                                stack-label
                                                label="Prihlasovacie meno *"
                                                hide-bottom-space
                                                clearable
                                                :lazy-rules="validationErrorCreateUserForm ? false : 'ondemand'"
                                                :rules="
                                                    $e.form.rules({
                                                        required: { required: true },
                                                        length: { min: 5, max: 20 },
                                                    })
                                                "
                                            />
                                        </div>
                                        <div class="col-12 flex items-center">
                                            <q-toggle
                                                v-model="createUser.confirm"
                                                checked-icon="check"
                                                label="Súhlasím s&nbsp;"
                                                color="green"
                                                unchecked-icon="clear"
                                                size="lg"
                                                :lazy-rules="validationErrorCreateUserForm ? false : 'ondemand'"
                                            />
                                            <a
                                                :href="vopUrl"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                obchodnými podmienkami
                                            </a>
                                        </div>
                                    </div>
                                    <q-separator class="q-my-lg" />
                                    <q-expansion-item
                                        label="Voliteľné údaje"
                                        header-class="groupTitle text-h6"
                                    >
                                        <div class="row q-col-gutter-md q-pt-md">
                                            <div class="col-6 col-sm-3">
                                                <q-input
                                                    v-model="createUser.titleBefore"
                                                    name="user-title_before"
                                                    outlined
                                                    stack-label
                                                    label="Titul pred"
                                                    hide-bottom-space
                                                    clearable
                                                    :lazy-rules="validationErrorCreateUserForm ? false : 'ondemand'"
                                                    :rules="$e.form.rules({ required: false, length: { max: 100 } })"
                                                />
                                            </div>
                                            <div class="col-6 col-sm-3">
                                                <q-input
                                                    v-model="createUser.titleAfter"
                                                    name="user-title_after"
                                                    outlined
                                                    stack-label
                                                    label="Titul za"
                                                    hide-bottom-space
                                                    clearable
                                                    :lazy-rules="validationErrorCreateUserForm ? false : 'ondemand'"
                                                    :rules="$e.form.rules({ required: false, length: { max: 100 } })"
                                                />
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <q-input
                                                    v-model="createUser.phone"
                                                    name="user-phone"
                                                    outlined
                                                    stack-label
                                                    label="Telefón"
                                                    hide-bottom-space
                                                    clearable
                                                    :lazy-rules="validationErrorCreateUserForm ? false : 'ondemand'"
                                                    :rules="$e.form.rules({ required: false, length: { max: 100 }, phone: { countries: 'sk' } })"
                                                />
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <q-input
                                                    v-model="createUser.identificationNumber"
                                                    name="user-identification_number"
                                                    outlined
                                                    stack-label
                                                    label="Rodné číslo"
                                                    hide-bottom-space
                                                    clearable
                                                    :lazy-rules="validationErrorCreateUserForm ? false : 'ondemand'"
                                                    :rules="$e.form.rules({ required: false, length: { max: 100 } })"
                                                />
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <q-date-time-input
                                                    name="user-birth_date"
                                                    v-model="createUser.birthDate.date"
                                                    label="Dátum narodenia"
                                                    stack-label
                                                    outlined
                                                    hide-bottom-space
                                                    clearable
                                                    :include-seconds="false"
                                                    :lazy-rules="validationErrorCreateUserForm ? false : 'ondemand'"
                                                    :rules="$e.form.rules({ required: false, date: { format: 'DD.MM.YYYY' } })"
                                                />
                                            </div>
                                            <div class="col-12">
                                                <q-choose-input
                                                    v-model="createUser.addressTown"
                                                    name="user-address_town_id"
                                                    label="Štát, Mesto / Obec, PSČ"
                                                    outlined
                                                    stack-label
                                                    item-key-field="addressTownId"
                                                    :item-value-field="(item) => item.addressCountry.name + ', ' + item.name + ', ' + item.postcode"
                                                    grid-load-type="static"
                                                    :grid-columns="addressTownListColumns"
                                                    :grid-loading="loading"
                                                    confirm-on-select
                                                    hide-bottom-space
                                                    :choose-on-click="true"
                                                    :lazy-rules="validationErrorCreateUserForm ? false : 'ondemand'"
                                                    :rules="$e.form.rules({ required: false })"
                                                    @data-request="onAddressTownListRequest"
                                                    @data-request-load="onAddressTownListRequestLoad"
                                                />
                                            </div>
                                            <div class="col-12">
                                                <q-input
                                                    v-model="createUser.addressStreet"
                                                    name="user-address_street"
                                                    outlined
                                                    stack-label
                                                    type="text"
                                                    label="Ulica a číslo"
                                                    hide-bottom-space
                                                    :lazy-rules="validationErrorCreateUserForm ? false : 'ondemand'"
                                                    :rules="$e.form.rules({ required: false, length: { max: 100 } })"
                                                />
                                            </div>
                                        </div>
                                    </q-expansion-item>
                                </div>
                            </q-card-section>
                            <q-card-actions align="right">
                                <CButton
                                    :label="$q.screen.gt.xs ? 'Potvrdiť' : ''"
                                    icon="done"
                                    color="positive"
                                    type="submit"
                                />
                                <CButton
                                    outline
                                    label="Vyčistiť"
                                    color="primary"
                                    @click="$refs.createUserForm.reset()"
                                />
                                <CButton
                                    outline
                                    label="Zavrieť"
                                    color="primary"
                                    v-close-popup
                                />
                            </q-card-actions>
                        </q-form>
                    </q-card>
                </q-dialog>

                <div class="flex no-wrap full-width q-pa-md">
                    <div class="column items-center full-width">
                        <q-card
                            style="width: 100%; max-width: 400px"
                            class="no-shadow bg-transparent"
                        >
                            <q-card-section class="q-pa-md">
                                <q-img
                                    src="@/assets/elenaLogo.svg"
                                    loading="lazy"
                                    spinner-color="grey-7"
                                    fit="contain"
                                    class="logo"
                                />
                                <q-form
                                    ref="loginForm"
                                    @submit="onSubmitLogin"
                                    class="loginForm"
                                    v-if="!changePasswd"
                                >
                                    <q-input
                                        v-model="loginForm.username"
                                        label="Meno"
                                        type="text"
                                        outlined
                                        text-color="white"
                                        class="full-width q-mb-md"
                                        :class="inputClass"
                                        autocomplete="username"
                                        hide-bottom-space
                                        lazy-rules="ondemand"
                                        :rules="[(val) => (val && val.length > 0) || 'Meno je povinné']"
                                    >
                                        <template v-slot:prepend>
                                            <q-icon
                                                name="person"
                                                class="icon"
                                            />
                                        </template>
                                    </q-input>
                                    <q-input
                                        v-model="loginForm.password"
                                        label="Heslo"
                                        :type="loginForm.isPwd ? 'password' : 'text'"
                                        outlined
                                        class="full-width"
                                        :class="inputClass"
                                        autocomplete="current-password"
                                        hide-bottom-space
                                        lazy-rules="ondemand"
                                        :rules="[(val) => (val && val.length > 0) || 'Heslo je povinné']"
                                    >
                                        <template v-slot:prepend>
                                            <q-icon name="lock" />
                                        </template>
                                        <template v-slot:append>
                                            <q-icon
                                                :name="loginForm.isPwd ? 'visibility_off' : 'visibility'"
                                                class="cursor-pointer"
                                                @click="loginForm.isPwd = !loginForm.isPwd"
                                            />
                                        </template>
                                    </q-input>
                                    <div class="row">
                                        <q-space />
                                        <span
                                            class="forgetPasswd cursor-pointer"
                                            @click="forgetPasswd.dialog = true"
                                            >Zabudnuté heslo?</span
                                        >
                                    </div>
                                    <CButton
                                        color="primary"
                                        label="Prihlásiť sa"
                                        class="full-width q-mt-xl"
                                        type="submit"
                                    />
                                    <CButton
                                        color="white"
                                        text-color="primary"
                                        label="Registrácia"
                                        class="full-width q-mt-md"
                                        @click="createUser.dialog = true"
                                    />
                                </q-form>
                                <q-form
                                    ref="changePasswdForm"
                                    @submit="onSubmitChangePasswd"
                                    class="changePasswdForm"
                                    v-if="changePasswd"
                                >
                                    <div class="q-mb-lg column items-center text-center">
                                        <span class="text-h3 text-bold">Zmena hesla</span>
                                        <p class="q-mt-md">Pre pokračovanie do aplikácie je potrebné zadať nové heslo</p>
                                    </div>
                                    <q-input
                                        v-model="changePasswdForm.passwd1"
                                        label="Heslo"
                                        :type="changePasswdForm.isPwd ? 'password' : 'text'"
                                        outlined
                                        class="full-width"
                                        :class="inputClass"
                                        autocomplete="current-password"
                                        hide-bottom-space
                                        lazy-rules="ondemand"
                                        :rules="[(val) => (val && val.length > 0) || 'Heslo je povinné']"
                                    >
                                        <template v-slot:prepend>
                                            <q-icon name="lock" />
                                        </template>
                                        <template v-slot:append>
                                            <q-icon
                                                :name="changePasswdForm.isPwd ? 'visibility_off' : 'visibility'"
                                                class="cursor-pointer"
                                                @click="changePasswdForm.isPwd = !changePasswdForm.isPwd"
                                            />
                                        </template>
                                    </q-input>
                                    <q-input
                                        v-model="changePasswdForm.passwd2"
                                        label="Overenie hesla"
                                        :type="changePasswdForm.isPwd ? 'password' : 'text'"
                                        outlined
                                        class="full-width"
                                        :class="inputClass"
                                        autocomplete="current-password"
                                        hide-bottom-space
                                        lazy-rules="ondemand"
                                        :rules="[
                                            (val) => (val && val.length > 0) || 'Heslo je povinné',
                                            (val) => val == changePasswdForm.passwd1 || 'Heslá sa nezhodujú',
                                        ]"
                                    >
                                        <template v-slot:prepend>
                                            <q-icon name="lock" />
                                        </template>
                                        <template v-slot:append>
                                            <q-icon
                                                :name="changePasswdForm.isPwd ? 'visibility_off' : 'visibility'"
                                                class="cursor-pointer"
                                                @click="changePasswdForm.isPwd = !changePasswdForm.isPwd"
                                            />
                                        </template>
                                    </q-input>
                                    <CButton
                                        color="positive"
                                        label="Zmeniť heslo"
                                        class="full-width q-mt-xl cbutton"
                                        type="submit"
                                    />
                                </q-form>
                            </q-card-section>
                        </q-card>
                        <div
                            class="appInfo text-center"
                            v-html="getAppInfo()"
                        ></div>
                    </div>
                </div>
            </q-page>
        </q-page-container>
    </q-layout>
</template>

<script>
import CButton from '@/components/CButton.vue';
import { mapState } from 'vuex';
import { LocalStorage } from 'quasar';

export default {
    beforeRouteEnter(to, from, next) {
        LocalStorage.set('elenaOrganizationId', null);
        next();
    },
    mounted() {
        setTimeout(() => (this.mounted = true), 1000);
    },
    beforeCreate() {
        this.$store.commit('setMetaData', {
            title: 'Prihlásenie',
        });
    },
    data() {
        return {
            loading: false,
            validationErrorCreateUserForm: false,
            mounted: false,
            forgetPasswd: {
                dialog: false,
                mail: '',
            },
            loginForm: {
                username: '',
                password: '',
                isPwd: true,
            },
            changePasswd: false,
            changePasswdForm: {
                passwd1: '',
                passwd2: '',
                isPwd: true,
            },
            createUser: {
                dialog: false,
                firstName: '',
                lastName: '',
                mail: '',
                login: '',
                showLogin: false,
                confirm: false,
                titleBefore: '',
                titleAfter: '',
                phone: '',
                identificationNumber: '',
                birthDate: {},
                addressTown: '',
                addressStreet: '',
            },
            addressTownListColumns: [
                { label: 'Štát', name: 'addressCountry.name', field: 'addressCountry.name', width: 150, align: 'left', alignHeader: 'left' },
                { label: 'Mesto / Obec', name: 'name', field: 'name', width: 150, align: 'left', alignHeader: 'left' },
                { label: 'PSČ', name: 'postcode', field: 'postcode', width: 150, align: 'left', alignHeader: 'left' },
            ],
            datumNarodeniaTemp: null,
            vopUrl: `${window.config.webUrl}/vseobecne_obchodne_podmienky`,
        };
    },
    components: {
        CButton,
    },
    computed: {
        inputClass() {
            return {
                'q-mb-sm': true,
                'autofill-fix': true,
                'autofill-fix-transition': this.mounted,
            };
        },
        ...mapState(['appInfo']),
    },
    methods: {
        onSubmitLogin() {
            this.$e.user.login(this.loginForm.username, this.loginForm.password, (user) => {
                if (user === 'PASSWORD_CHANGE') {
                    this.changePasswd = true;
                } else {
                    this.$store.commit('setOrganizationItem', null);
                    this.$router.push('/');
                    this.$router.login();
                }
            });
        },
        getAppInfo() {
            return (
                'Verzia aplikácie: ' +
                this.appInfo.applicationVersion +
                '<br/>Dátum vydania: ' +
                this.appInfo.releaseDate +
                '<br/>Prostredie: ' +
                this.appInfo.systemTypeName
            );
        },
        onSubmitForgetPasswordEmail() {
            let methodName = 'sendUserAccountPassword';
            let methodParameters = { 'user-account-mail': this.forgetPasswd.mail };
            this.$e.io.callMethod(methodName, methodParameters, {
                success: (data) => {
                    this.forgetPasswd.dialog = false;
                    this.forgetPasswd.mail = null;
                    this.$e.dialog.success('Vaše dočasné heslo bolo odoslané na email');
                },
                error: () => {},
                afterError: () => {},
            });
        },
        onSubmitChangePasswd() {
            let methodName = 'changeUserAccountPassword';
            let methodParameters = { 'user-account-password': this.changePasswdForm.passwd1, 'user-account-password_confirm': this.changePasswdForm.passwd2 };
            this.$e.io.callMethod(methodName, methodParameters, {
                success: (user) => {
                    if (user instanceof Object) {
                        this.$e.user.setUser(user);
                        this.$router.login();
                    }
                },
                error: () => {},
                afterError: () => {},
            });
        },
        onSubmitCreateUserForm() {
            if (!this.createUser.confirm) {
                this.$e.dialog.error('Súhlas s obchodnými podmienkami je povinný');
                return;
            }
            this.$e.io.callMethodByForm(this.$refs.createUserForm.$el, 'createUserProfile', null, (user) => {
                this.$e.dialog.success('Na Váš email bolo zaslané heslo, ktoré je potrebné použiť pri prihlásení.');
                this.$refs.createUserForm.reset();
                this.createUser.dialog = false;
            });
        },
        onResetCreateUserForm() {
            this.validationErrorCreateUserForm = false;
            this.createUser = {
                dialog: true,
                firstName: '',
                lastName: '',
                mail: '',
                login: '',
                showLogin: false,
                titleBefore: '',
                titleAfter: '',
                phone: '',
                identificationNumber: '',
                birthDate: {},
                addressTown: '',
                addressStreet: '',
            };
        },
        onValidationSuccessCreateUserForm() {
            this.validationErrorCreateUserForm = false;
        },
        onValidationErrorCreateUserForm() {
            this.validationErrorCreateUserForm = true;
        },
        fillLogin() {
            if (!this.$e.is.empty(this.createUser.firstName) && !this.$e.is.empty(this.createUser.lastName) && this.$e.is.empty(this.createUser.login)) {
                this.createUser.showLogin = true;
                this.createUser.login =
                    this.createUser.firstName.removeDiacritics().toLowerCase() +
                    '.' +
                    this.createUser.lastName.removeDiacritics().toLowerCase() +
                    Math.floor(Math.random() * (999 - 100 + 1) + 100);
            }
        },
        beforeDatumNarodeniaShow() {
            const dateInput = this.createUser.birthDate.date;
            this.datumNarodeniaTemp = dateInput ? dateInput : null;
        },
        setDatumNarodenia() {
            const dateTemp = this.datumNarodeniaTemp;
            if (this.datumNarodeniaTemp) {
                this.createUser.birthDate.date = dateTemp;
            }
        },
        onAddressTownListRequest(requestProps) {
            const loadConfig = requestProps.loadConfig;
            this.$e.io.callMethod(
                'getAddressTownList',
                {
                    'address_town-load_config': loadConfig,
                    'address_town-only_valid': true,
                },
                (addressTownList) => {
                    requestProps.callback(addressTownList);
                }
            );
        },
        onAddressTownListRequestLoad(requestProps) {
            this.loading = true;
            this.$e.io.callMethod(
                'getAddressTownMap',
                {
                    'address_town-address_town_id_set': requestProps.keys,
                },
                {
                    success: (addressTownMap) => {
                        requestProps.callback(addressTownMap);
                        this.loading = false;
                    },
                    error: () => {
                        this.loading = false;
                    },
                },
                {
                    loadingScreen: false,
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    position: fixed;
    width: calc(100% - 48px);
    max-height: 200px;
    top: 32px;
    left: 0;
    margin: 24px;

    @media (max-height: $breakpoint-sm-max) {
        position: relative;
        top: 0px;
        margin: 0 0 56px 0;
        width: 100%;
    }
}
.appInfo {
    font-size: 13px;
    margin-top: 48px;
}

.bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-image: url(@/assets/loginbg.jpg);
    background-size: cover;
    background-position: center;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(180, 187, 201, 0.8);
        z-index: 0;
    }
}

:deep() .q-page-container {
    background-image: none;
}

.loginPanel {
    margin-bottom: 100px;
}

.loginForm,
.changePasswdForm {
    .CButton {
        min-height: 56px;
    }

    :deep() .q-input {
        .q-field__control {
            background: rgba(255, 255, 255, 0.5);
            color: $primary;
            @include transition(background);

            &:hover {
                background: rgba(255, 255, 255, 0.5);
            }

            &:before {
                border-color: transparent;
            }
        }
    }
}
</style>
