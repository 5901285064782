<template>
    <div class="menuItem">
        <q-separator
            v-if="show && separatorBefore"
            class="q-my-md"
        />
        <q-item
            v-if="show && isButton"
            clickable
            v-ripple
            class="q-mb-xs q-py-sm"
            :class="[textColor ? `text-${textColor}` : 'text-white', { [`bg-${bgColor}`]: bgColor }]"
            :to="to"
            @click="$router.push(routerPush)"
        >
            <q-item-section avatar>
                <q-icon
                    :name="icon"
                    size="md"
                >
                    <q-badge
                        rounded
                        :color="badge.color || 'red'"
                        floating
                        :class="$route.name != to.substring(1, to.length) ? 'pulse' : ''"
                        v-if="badge && badge.show"
                    >
                        {{ badge.value }}
                    </q-badge>
                    <q-tooltip
                        v-if="badge && badge.show && badge.tooltip"
                        anchor="bottom left"
                        self="center left"
                        class="bg-white text-body2 text-dark gt-sm"
                        :offset="[0, 30]"
                    >
                        {{ badge.tooltip }}
                    </q-tooltip>
                    <q-tooltip
                        v-if="!badge && iconTooltip"
                        anchor="bottom left"
                        self="center left"
                        class="bg-white text-body2 text-dark gt-sm"
                        :offset="[0, 30]"
                    >
                        {{ iconTooltip }}
                    </q-tooltip>
                </q-icon>
            </q-item-section>
            <q-item-section>{{ name }}</q-item-section>
            <q-tooltip
                v-if="tooltip"
                anchor="center right"
                self="center left"
                :delay="1000"
                class="bg-white text-body2 text-dark gt-sm"
                transition-show="jump-right"
                transition-hide="jump-left"
            >
                {{ tooltip }}
            </q-tooltip>
        </q-item>
        <q-item
            v-if="!sublist && show && !isButton"
            clickable
            v-ripple
            class="q-mb-xs q-py-sm text-white"
            :to="to"
        >
            <q-item-section avatar>
                <q-icon
                    :name="icon"
                    size="md"
                >
                    <q-badge
                        rounded
                        :color="badge.color || 'red'"
                        floating
                        :class="$route.name != to.substring(1, to.length) ? 'pulse' : ''"
                        v-if="badge && badge.show"
                    >
                        {{ badge.value }}
                    </q-badge>
                    <q-tooltip
                        v-if="badge && badge.show && badge.tooltip"
                        anchor="bottom left"
                        self="center left"
                        class="bg-white text-body2 text-dark gt-sm"
                        :offset="[0, 30]"
                    >
                        {{ badge.tooltip }}
                    </q-tooltip>
                    <q-tooltip
                        v-if="!badge && iconTooltip"
                        anchor="bottom left"
                        self="center left"
                        class="bg-white text-body2 text-dark gt-sm"
                        :offset="[0, 30]"
                    >
                        {{ iconTooltip }}
                    </q-tooltip>
                </q-icon>
            </q-item-section>
            <q-item-section>{{ name }}</q-item-section>
            <q-tooltip
                v-if="tooltip"
                anchor="center right"
                self="center left"
                :delay="1000"
                class="bg-white text-body2 text-dark gt-sm"
                transition-show="jump-right"
                transition-hide="jump-left"
            >
                {{ tooltip }}
            </q-tooltip>
        </q-item>
        <q-expansion-item
            v-if="sublist && show"
            clickable
            class="q-mb-xs text-white"
            :to="to"
            hide-expand-icon
            default-opened
        >
            <template v-slot:header>
                <div class="row">
                    <q-item-section avatar>
                        <q-icon
                            :name="icon"
                            size="md"
                        >
                            <q-badge
                                rounded
                                :color="badge.color || 'red'"
                                floating
                                v-if="badge && badge.show"
                                :class="$route.name != to.substring(1, to.length) ? 'pulse' : ''"
                            >
                                {{ badge.value }}
                            </q-badge>
                        </q-icon>
                    </q-item-section>
                    <q-item-section>{{ name }}</q-item-section>
                </div>
                <q-tooltip
                    anchor="center right"
                    self="center left"
                    :delay="1000"
                    class="bg-white text-body2 text-dark gt-sm"
                    transition-show="jump-right"
                    transition-hide="jump-left"
                >
                    {{ tooltip }}
                </q-tooltip>
            </template>
            <q-item
                v-for="(child, i) in sublist"
                :key="i"
                :to="child.to"
                clickable
                v-ripple
                class="q-mb-xs q-py-sm text-white"
            >
                <q-item-section>
                    {{ child.name }}
                    <q-badge
                        rounded
                        :color="child.badge.color || 'red'"
                        floating
                        style="align-items: center; left: 130px; top: 16px"
                        v-if="child.badge && child.badge.show"
                    >
                        {{ child.badge.value }}
                    </q-badge>
                </q-item-section>
                <q-tooltip
                    anchor="center right"
                    self="center left"
                    :delay="1000"
                    class="bg-white text-body2 text-dark"
                    transition-show="jump-right"
                    transition-hide="jump-left"
                >
                    {{ child.tooltip }}
                </q-tooltip>
            </q-item>
        </q-expansion-item>
        <q-separator
            v-if="show && separatorAfter"
            class="q-my-md"
        />
    </div>
</template>

<script>
export default {
    name: 'NavigationButton',
    props: [
        'name',
        'icon',
        'iconTooltip',
        'tooltip',
        'to',
        'sublist',
        'badge',
        'separatorBefore',
        'separatorAfter',
        'show',
        'routerPush',
        'isButton',
        'bgColor',
        'textColor',
    ],
    setup() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.menuItem {
    :deep() .q-item {
        @include border-radius(15px);
        background: none;

        &.q-router-link--active {
            background: rgb(238, 238, 238);
            background: linear-gradient(90deg, rgba(238, 238, 238, 1) 30%, rgba(164, 195, 227, 1) 100%);
            color: $primary !important;
            @include box-shadow(0px 10px 15px 0px rgba($primary, 0.3));
        }

        .q-item__section--avatar {
            padding-right: 0;
        }

        .q-badge {
            height: 16px;
            width: 16px;
            align-items: unset;
            justify-content: center;
            padding: 0;
            font-size: 12px;
        }
    }

    :deep() .q-expansion-item {
        div.row {
            width: 100%;
        }
        .q-expansion-item__container > .q-item {
            padding: 4px 0 4px 16px;
            margin-bottom: 4px;
        }

        .q-expansion-item__content > .q-item {
            margin-left: 56px;
            width: calc(100% - 56px);
            &::before {
                content: '';
                position: absolute;
                top: 0;
                height: calc(100% + 4px);
                width: 2px;
                background: white;
                left: -12px;
            }

            &:last-child::before {
                height: 100%;
            }
        }
    }
}

.pulse {
    @include animation(pulse-animation 5s infinite);
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5);
        transform: scale(1.7);
    }
    10% {
        transform: scale(1);
    }
    30% {
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
    95% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.9);
    }
}
</style>
