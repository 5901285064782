<template>
    <router-view />
</template>

<script>
import { createMetaMixin } from 'quasar';
import { date } from 'quasar';
import $store from '@/store';
import { LocalStorage } from 'quasar';

export default {
    name: 'App',
    created() {
        const loadUser = () => {
            const orgAcceptCode = this.$route.query.org_acc;
            this.$e.user.load((user) => {
                if (user == null) {
                    LocalStorage.set('elenaOrganizationId', null);
                    if (process.env.NODE_ENV == 'development') {
                        this.$router.logout();
                    } else {
                        window.location.replace(`${window.config.webUrl}${orgAcceptCode ? '/?org_acc=' + orgAcceptCode : ''}`);
                    }
                } else {
                    //Accept organization's invitation
                    if (!this.$e.is.empty(orgAcceptCode)) {
                        this.$e.io.callMethod(
                            'acceptInviteOrganizationUser',
                            { 'organization_user-code': orgAcceptCode },
                            {
                                success: () => {
                                    this.$e.dialog.success(
                                        'Pozvánku do organizácie ste úspešne akcetpovali. Počkajte, kým správca organizácie Vaše pridanie potvrdí.',
                                        () => {
                                            this.$router.push('/');
                                        }
                                    );
                                },
                                error: (error) => {},
                            },

                            { loadingScreen: false }
                        );
                    }

                    const localeStorageOrganizationId = LocalStorage.getItem('elenaOrganizationId');
                    if (localeStorageOrganizationId) {
                        this.$e.io.callMethod(
                            'getOrganizationUserForLoggedUser',
                            { 'organization_user-organization_id': localeStorageOrganizationId, 'organization_user-check': 'false' },
                            {
                                success: (organizationUser) => {
                                    if (organizationUser) {
                                        $store.commit('setOrganizationItem', organizationUser);
                                    } else {
                                        $store.commit('setOrganizationItem', null);
                                        this.$router.push('/');
                                    }
                                    this.$router.login();
                                },
                                error: (error) => {},
                            }
                        );
                    } else {
                        $store.commit('setOrganizationItem', null);
                        this.$router.push('/');
                        this.$router.login();
                    }
                }
            });
        };

        this.$e.storageKey = 'elena-';
        this.$e.io.callMethod('getApplicationInfo', null, {
            success: (info) => {
                this.title = info.applicationName;
                if (info.systemTypeCode === 'D') {
                    this.system = 'development';
                } else if (info.systemTypeCode === 'T') {
                    this.system = 'test';
                } else if (info.systemTypeCode === 'P') {
                    this.system = 'product';
                }

                let appInfo = {};
                appInfo.applicationName = info.applicationName;
                appInfo.applicationVersion = info.applicationVersion;
                appInfo.databaseVersion = info.databaseVersion;
                appInfo.releaseDate = info.releaseDate;
                appInfo.systemTypeName = info.systemTypeName;
                this.$store.commit('setAppInfo', appInfo);

                const storageKey = 'enfis-' + info.applicationName.toLowerCase() + '-';
                this.$e.storageKey = storageKey;
                this.$e.user.onSetUser((user) => {
                    this.$e.storageKey = storageKey + user.account.login + '-';
                });
                loadUser();
            },
            error: (error) => {
                loadUser();
                return false;
            },
        });

        //Custom functions
        this.$e.dateToCalendarDate = (d) => {
            const parts = d.split(' ');
            const datePart = parts[0];
            const timePart = parts[1];

            const [day, month, year] = datePart.split('.');
            const dt = new Date(`${year}-${month}-${day}`);

            return date.formatDate(dt.getTime(), 'YYYY/MM/DD');
        };
    },

    data() {
        return {};
    },
    mixins: [
        createMetaMixin(function () {
            let metaDataStore = this.$store.state.metaData;
            let metaDataObj = {
                title: 'Elena portál | ' + metaDataStore.title,
                meta: {
                    title: { name: 'title', content: 'Elena portál | ' + metaDataStore.title },
                    description: { name: 'description', content: metaDataStore.description },

                    //      Open Graph / Facebook     //
                    ogType: {
                        property: 'og:type',
                        content: 'website',
                    },
                    ogUrl: {
                        property: 'og:url',
                        content: window.location.href,
                    },
                    ogTitle: {
                        property: 'og:title',
                        content: 'Elena portál | ' + metaDataStore.title,
                    },
                    ogDescription: {
                        property: 'og:description',
                        content: metaDataStore.description,
                    },
                    ogImage: {
                        property: 'og:image',
                        content: metaDataStore.image,
                    },

                    //      Twitter     //
                    twitterCard: {
                        property: 'twitter:card',
                        content: 'summary_large_image',
                    },
                    twitterUrl: {
                        property: 'twitter:url',
                        content: window.location.href,
                    },
                    twitterTitle: {
                        property: 'twitter:title',
                        content: 'Elena portál | ' + metaDataStore.title,
                    },
                    twitterDescription: {
                        property: 'twitter:description',
                        content: metaDataStore.description,
                    },
                    twitterImage: {
                        property: 'twitter:image',
                        content: metaDataStore.image,
                    },
                },
            };
            return metaDataObj;
        }),
    ],
};
</script>
