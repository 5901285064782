import { createStore } from 'vuex';
import LocalStorage from 'quasar/src/plugins/LocalStorage.js';;

export default createStore({
    state: {
        appInfo: null,
        appTitle: null,
        appBack: null,
        metaData: {
            title: '',
            description: null,
            keywords: null,
            image: null,
        },
        organizationItem: null,
    },
    mutations: {
        setAppInfo(state, appInfo) {
            state.appInfo = appInfo;
        },
        setAppTitle(state, appTitle) {
            state.appTitle = appTitle;
        },
        setAppBack(state, appBack) {
            state.appBack = appBack;
        },
        setAppSettingsStore(state, appSettingsStore) {
            state.appSettingsStore = appSettingsStore;
        },
        setMetaData(state, metaData) {
            if (metaData == null || metaData.length <= 0) {
                metaData = {
                    title: 'Elena portál',
                    description: 'Elena portál',
                    image: '',
                };
            } else {
                if (metaData.title == null || metaData.title.length <= 0) {
                    metaData.title = 'Elena portál';
                }

                if (metaData.description == null || metaData.description.length <= 0) {
                    metaData.description = 'Elena portál';
                }

                if (metaData.image == null || metaData.image.length <= 0) {
                    metaData.image = '';
                }
            }

            state.metaData = metaData;
        },
        setOrganizationItem(state, organization) {
            state.organizationItem = organization;
            LocalStorage.set('elenaOrganizationId', organization?.organizationId || organization);
        },
        setOrder(state, newOrder) {
            state.order = newOrder;
        },
    },
    getters: {
        isAdminForCurrentOrganization(state) {
            return state.organizationItem?.role === 1;
        },
    },
    actions: {},
    modules: {},
});
