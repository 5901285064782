<template>
    <q-btn
        :color="color"
        :icon="icon"
        :label="label"
        class="CButton"
    >
        <slot></slot>
    </q-btn>
</template>

<script>
export default {
    name: 'CButton',
    props: {
        color: String,
        icon: String,
        label: String,
    },
    setup() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.q-btn {
    padding: 10px;
    min-height: 48px;
    &:not(.q-btn--flat, .q-btn--outline) {
        &::before {
            @include box-shadow(0px 3px 5px 0px rgba($primary, 0.15), inset 0px 0px 0px 1px rgba($primary, 0.07));
        }

        &.q-btn--actionable.q-btn--standard:active:before,
        &.q-btn--actionable.q-btn--standard.q-btn--active:before {
            @include box-shadow(0px 3px 5px 0px rgba($primary, 0.15), 0px 3px 5px 0px rgba($primary, 0.15), inset 0px 0px 0px 1px rgba($primary, 0.07));
        }
    }
}

:deep() .q-btn__content {
    .block {
        font-size: em(14);
        margin: 0 8px;
    }
    .q-badge {
        &.q-badge--floating {
            top: 1px;
            right: -4px;
        }
    }
    .q-icon {
        margin: 0;
        min-width: 28px;
        min-height: 28px;
    }
}
</style>
