<template>
    <router-view />
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>
