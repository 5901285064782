import './styles/quasar.scss';
import lang from 'quasar/lang/sk.js';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Meta from 'quasar/src/plugins/Meta.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';;

// To be used on app.use(Quasar, { ... })
export default {
    config: {},
    plugins: { AppFullscreen, Dialog, Meta, Notify, Loading, LocalStorage },
    lang: lang,
};
