<template>
    <q-dialog
        v-model="hintDialog.show"
        class="hintDialog"
    >
        <q-card>
            <q-toolbar>
                <q-avatar>
                    <q-icon
                        name="lightbulb"
                        size="md"
                    />
                </q-avatar>
                <q-toolbar-title>Pomocník</q-toolbar-title>
                <q-btn
                    dense
                    flat
                    icon="close"
                    v-close-popup
                >
                    <q-tooltip class="bg-white text-body2 text-primary">Zavrieť</q-tooltip>
                </q-btn>
            </q-toolbar>

            <q-card-section class="scroll">
                <div class="row q-col-gutter-md">
                    <q-resize-observer @resize="onResize" />
                    <div
                        class="col-12 message text-h5"
                        style="white-space: pre-line"
                        v-if="hintDialog.message"
                    >
                        {{ hintDialog.message }}
                        <q-separator class="q-mt-md" />
                    </div>
                    <div
                        class="col-12 content"
                        v-html="hintDialog.content"
                    ></div>
                </div>
            </q-card-section>
            <q-card-actions align="right">
                <CButton
                    outline
                    label="Zavrieť"
                    color="primary"
                    v-close-popup
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
    <q-dialog v-model="infoDialog">
        <q-card>
            <q-toolbar> </q-toolbar>
            <q-card-section class="scroll">
                <div class="row">
                    <div class="col-12 text-center">
                        <q-img
                            src="@/assets/elenaLogo.svg"
                            style="width: 450px; max-width: 100%"
                            spinner-color="primary"
                        />
                    </div>
                    <div
                        class="col-12 q-mt-xl text-center"
                        v-html="getAppInfo()"
                    ></div>
                    <div class="col-12 q-mt-lg text-center">
                        <div
                            id="links2"
                            class="row"
                        >
                            <div class="col-12 text-center">
                                <a
                                    :href="vopUrl"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Všeobecné obchodné podmienky
                                </a>
                            </div>
                            <div class="col-12 text-center">
                                <a
                                    :href="psouUrl"
                                    target="_blank"
                                >
                                    Pravidlá spracovania osobných údajov
                                </a>
                            </div>
                            <div class="q-mt-xl col-12 text-center">
                                <div
                                    class="text-h7"
                                    style="text-decoration: underline"
                                >
                                    Ste prihlásený ako:
                                </div>
                                <div class="text-h6">{{ $e.user.getUser().fullName }}</div>
                                <div>Prihlasovacie meno: {{ $e.user.getUser().account.login }}</div>
                                <div>Email: {{ $e.user.getUser().account.mail }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </q-card-section>
            <q-card-actions align="right">
                <CButton
                    outline
                    label="Zavrieť"
                    color="primary"
                    v-close-popup
                />
            </q-card-actions>
        </q-card>
    </q-dialog>

    <q-layout view="lHh Lpr lFf">
        <q-header
            reveal
            class="bg-white"
        >
            <q-toolbar :class="$q.screen.lt.md ? 'q-pl-none q-pr-md' : 'q-px-lg'">
                <CButton
                    flat
                    icon="menu"
                    aria-label="Menu"
                    color="white"
                    text-color="dark"
                    @click="leftDrawerOpen = !leftDrawerOpen"
                    v-show="!leftDrawerOpen"
                />
                <CButton
                    v-if="appBack"
                    :to="typeof appBack === 'function' ? null : appBack"
                    icon="arrow_back_ios_new"
                    label="Späť"
                    color="white"
                    text-color="primary"
                    unelevated
                    class="q-mr-md"
                    @click="typeof appBack === 'function' && appBack()"
                />
                <q-toolbar-title
                    text-color="$dark"
                    class="text-bold q-pl-none q-pr-lg"
                >
                    {{ appTitle }}
                </q-toolbar-title>
                <div class="floatingHeaderIcons q-py-sm lt-md">
                    <q-fab
                        vertical-actions-align="right"
                        direction="down"
                    >
                        <template v-slot:icon>
                            <q-icon
                                name="apps"
                                size="md"
                            />
                        </template>
                        <q-fab-action to="/">
                            <template v-slot:default>
                                <span class="block">Domov</span>
                                <q-icon
                                    name="home"
                                    size="md"
                                />
                            </template>
                        </q-fab-action>
                        <!-- <q-fab-action to="/notifikacie">
                            <template v-slot:default>
                                <span class="block">Notifikácie</span>
                                <q-icon
                                    name="notifications"
                                    size="md"
                                />
                                <q-badge
                                    rounded
                                    color="red"
                                    floating
                                    v-show="notifiCount > 0"
                                >
                                    {{ notifiCount }}
                                </q-badge>
                            </template>
                        </q-fab-action> -->
                        <q-fab-action @click="showHintDialog">
                            <template v-slot:default>
                                <span class="block">Pomocník</span>
                                <q-icon
                                    name="lightbulb"
                                    size="md"
                                />
                            </template>
                        </q-fab-action>
                        <q-fab-action
                            class="logout"
                            @click="logout"
                        >
                            <template v-slot:default>
                                <span class="block">Odhlásiť</span>
                                <q-icon
                                    name="power_settings_new"
                                    size="md"
                                />
                            </template>
                        </q-fab-action>
                    </q-fab>
                </div>
                <div class="q-py-md header-icons q-gutter-sm gt-sm">
                    <CButton
                        color="white"
                        icon="home"
                        text-color="primary"
                        to="/"
                    >
                        <q-tooltip
                            anchor="bottom middle"
                            self="top middle"
                            class="bg-white text-body2 text-dark"
                            transition-show="jump-down"
                            transition-hide="jump-up"
                        >
                            Domov
                        </q-tooltip>
                    </CButton>
                    <!-- <q-btn
                        class="q-pa-sm q-ml-sm"
                        color="white"
                        to="/notifikacie"
                    >
                        <template v-slot:default>
                            <q-icon
                                name="notifications"
                                size="md"
                            />
                            <q-badge
                                rounded
                                color="red"
                                floating
                                v-show="notifiCount > 0"
                            >
                                {{ notifiCount }}
                            </q-badge>
                            <q-tooltip
                                anchor="bottom middle"
                                self="top middle"
                                class="bg-white text-body2 text-dark"
                                transition-show="jump-down"
                                transition-hide="jump-up"
                            >
                                Notifikácie
                            </q-tooltip>
                        </template>
                    </q-btn> -->
                    <CButton
                        color="white"
                        icon="lightbulb"
                        text-color="primary"
                        @click="showHintDialog"
                    >
                        <q-tooltip
                            anchor="bottom middle"
                            self="top middle"
                            class="bg-white text-body2 text-dark"
                            transition-show="jump-down"
                            transition-hide="jump-up"
                        >
                            Pomocník
                        </q-tooltip>
                    </CButton>
                    <CButton
                        label="Odhlásiť sa"
                        color="white"
                        icon="power_settings_new"
                        text-color="negative"
                        class="logout"
                        @click="logout"
                    >
                    </CButton>
                </div>
            </q-toolbar>
        </q-header>

        <q-drawer
            id="navPanel"
            v-model="leftDrawerOpen"
            show-if-above
            :width="300"
        >
            <!-- :breakpoint="1040" :width="300" -->
            <div class="column no-wrap full-height">
                <div
                    id="logoPanel"
                    class="row justify-center items-end"
                    style="flex: 0 0 auto"
                >
                    <q-img
                        src="@/assets/elenaLogoDark.svg"
                        class="logoImg"
                        spinner-color="white"
                    />
                </div>
                <div
                    id="companyPanel"
                    class="q-px-md"
                    v-if="isSetOrganization"
                >
                    <div class="row text-white q-mb-sm justify-center">Vybraná organizácia</div>
                    <q-select
                        standout
                        v-model="organizationItem"
                        hide-dropdown-icon
                        :option-value="(item) => item"
                        :option-label="(item) => item.organization.name"
                        :options="organizationList"
                        @filter="onOrganizationFilter"
                        @update:model-value="onOrganizationChange"
                    >
                        <template v-slot:no-option>
                            <q-item>
                                <q-item-section class="text-grey"> Žiadne dáta </q-item-section>
                            </q-item>
                        </template>
                        <template v-slot:append>
                            <q-icon
                                name="change_circle"
                                size="md"
                            />
                        </template>
                        <template v-slot:selected-item="scope">
                            <div class="ellipsis">
                                {{ scope.opt.organization.name }}
                                <q-tooltip
                                    anchor="bottom left"
                                    self="top left"
                                    :delay="500"
                                    class="bg-white text-body2 text-dark"
                                >
                                    <q-item class="q-pa-none">
                                        <q-item-section>
                                            <q-item-label>{{ organizationItem.organization.name }}</q-item-label>
                                            <q-item-label caption>{{ organizationItem.roleLabel }}</q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </q-tooltip>
                            </div>
                        </template>
                        <template v-slot:option="scope">
                            <q-item v-bind="scope.itemProps">
                                <q-item-section>
                                    <q-item-label>{{ scope.opt.organization.name }}</q-item-label>
                                </q-item-section>
                                <q-item-section side>
                                    <q-badge
                                        outline
                                        :color="getRoleColor(scope.opt.role)"
                                        :label="scope.opt.roleLabel.split(' ')[0]"
                                    />
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-select>
                </div>
                <q-scroll-area
                    id="menuItemPanel"
                    :thumb-style="{ right: '2px', background: 'white', opacity: 0.7 }"
                    class="col-grow"
                    style="min-height: 150px"
                    visible
                >
                    <q-list class="q-px-md q-pb-md">
                        <NavigationButton
                            v-for="(item, i) in getNavigationItemList"
                            v-bind="item"
                            :key="i"
                        />
                    </q-list>
                </q-scroll-area>
                <div
                    id="bottomPanel"
                    class="q-px-md"
                >
                    <q-separator class="q-my-sm" />
                    <div
                        id="links1"
                        class="row"
                    >
                        <div class="col-4 text-center">
                            <a
                                :href="webUrl"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Web
                            </a>
                        </div>
                        <div class="col-4 text-center">
                            <a
                                :href="otazkyUrl"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Otázky
                            </a>
                        </div>
                        <div class="col-4 text-center">
                            <router-link to="/kontakt">Kontakt</router-link>
                        </div>
                    </div>
                    <q-separator class="q-my-sm" />

                    <div
                        id="copyright"
                        class="row q-my-sm items-center"
                    >
                        <div class="flex items-center justify-center col-12 text-center text-white copyrightPanel">
                            Copyright © 2023 ELENA, a.s.
                            <q-btn
                                unelevated
                                round
                                text-color="white"
                                icon="o_info"
                                class="q-pa-none"
                                @click="infoDialog = true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </q-drawer>

        <q-page-container>
            <router-view v-slot="{ Component, route }">
                <q-page
                    :key="route.name"
                    :style-fn="$e.utils.pageStyleFn"
                >
                    <component :is="Component" />
                </q-page>
            </router-view>
        </q-page-container>
    </q-layout>
</template>

<script>
import { LocalStorage } from 'quasar';
import $e from '@enfis/quasar';
import { mapState } from 'vuex';
import { date } from 'quasar';
import NavigationButton from '@/components/NavigationButton.vue';
import CButton from '@/components/CButton.vue';

const notifiCount = 1;
let loggedUserCheck = null;

export default {
    components: {
        NavigationButton,
        CButton,
    },
    beforeCreate() {
        let metaData = {
            title: 'Vítajte',
        };
        this.$store.commit('setMetaData', metaData);

        const SessionStorageNotify = LocalStorage.getItem('elenaNotify');
        if ($e.is.empty(SessionStorageNotify)) {
            LocalStorage.set('elenaNotify', true);

            this.$q.notify({
                message:
                    '<div class="row full-height full-width no-wrap"><div class="img"></div><div class="content"><div class="text-h6 q-mb-md"">Vítajte!</div><p>Aby sme vám prácu uľahčili, pripravili sme pre vás Pomocníka. Nájdete ho na každej jednej podstránke portálu.</p><p>Stačí vpravo hore kliknúť na ikonku žiarovky.</p><p>Prajeme veľa úspechov!</p></div>',
                color: 'primary',
                classes: 'hintSmall',
                position: 'bottom-right',
                multiLine: true,
                html: true,
                timeout: 1000000000,
                actions: [
                    {
                        label: 'Zavrieť',
                        color: 'white',
                        class: 'q-btn--outline',
                    },
                ],
            });
            //100000
        }

        const connectionProblem = () => {
            this.$e.dialog.warning('Spojenie sa prerušilo. Ak chcete pokračovať, načítajte stránku znovu, prosím.');
            clearInterval(loggedUserCheck);
        };

        loggedUserCheck = setInterval(() => {
            this.$e.io.callMethod(
                'isLoggedUser',
                null,
                {
                    success: (isLogged) => {
                        if (!isLogged) {
                            connectionProblem();
                        }
                    },
                    error: () => {
                        connectionProblem();
                        return false;
                    },
                    afterError: () => {},
                },
                {
                    loadingScreen: false,
                    error: () => {
                        connectionProblem();
                        return false;
                    },
                }
            );
        }, 5 * 60 * 1000);
    },
    data() {
        return {
            infoDialog: false,
            hintDialog: {
                show: false,
                content: null,
                message: null,
            },
            routeName: null,
            leftDrawerOpen: false,
            notifiCount,
            organizationList: null,
            existOrderForApplicantRes: false,
            subscription: {},
            webUrl: window.config.webUrl,
            otazkyUrl: `${window.config.webUrl}/otazky`,
            vopUrl: `${window.config.webUrl}/vseobecne_obchodne_podmienky`,
            psouUrl: `${window.config.webUrl}/pravidla_spracovania_osobnych_udajov`,
        };
    },
    methods: {
        showHintDialog() {
            let methodName = 'getHelperByPage';
            let methodParameters = {
                'helper-page': this.$route.name,
            };
            this.$e.io.callMethod(methodName, methodParameters, {
                success: (data) => {
                    const hint = data;

                    this.hintDialog.content = hint.detail;
                    this.hintDialog.message = hint.message;
                    this.hintDialog = {
                        message: hint.message,
                        content: hint.detail,
                        show: true,
                    };
                },
                error: () => {
                    console.log('Pomocnik router_name: ', this.$route.name);
                    this.$e.dialog.info({
                        type: 'html',
                        message: 'Pre túto podstránku zatiaľ nie je dostupná žiadna nápoveda..<div class="q-mt-lg">...ale pracujeme na tom :)</div>',
                    });
                    return false;
                },
                afterError: () => {},
            });
        },
        logout() {
            this.$e.user.logout(() => {
                LocalStorage.set('elenaOrganizationId', null);
                if (process.env.NODE_ENV == 'development') {
                    this.$router.logout();
                } else {
                    window.location.replace(`${window.config.webUrl}`);
                }
            });
        },
        onOrganizationFilter(val, update, abort) {
            let methodName = 'getOrganizationUserListForLoggedUser';
            let methodParameters = {};
            this.$e.io.callMethod(
                methodName,
                methodParameters,
                {
                    success: (data) => {
                        this.organizationList = data;
                        update(() => {});
                    },
                    error: () => {},
                    afterError: () => {},
                },
                { loadingScreen: false }
            );
        },
        onOrganizationChange() {
            this.$router.push('/mojezakazky');
        },
        getRoleColor(roleId) {
            switch (roleId) {
                case 1:
                    return 'negative';

                case 2:
                    return 'primary';

                case 3:
                    return 'secondary';

                case 4:
                    return 'info';

                default:
                    break;
            }
        },
        onResize(size) {
            const hintDialog = document.getElementsByClassName('hintDialog');
            const iframes = document.getElementsByTagName('iframe');
            for (const iframe of iframes) {
                iframe.setAttribute('height', size.width / 1.77);
            }
        },
        isShowSubscription() {
            if (this.organizationItem?.organization?.subscription) {
                const subscriptionDate = date.extractDate(this.organizationItem.organization.subscriptionDate.dateTime, 'DD.MM.YYYY HH:mm:ss');
                const unit = 'days';
                const diff = date.getDateDiff(subscriptionDate, new Date(), unit);
                let day = '';
                if (diff == 0) {
                    day = 'dnes';
                } else if (diff == 1) {
                    day = 'deň';
                } else if (diff > 1 && diff < 5) {
                    day = 'dni';
                } else {
                    day = 'dní';
                }

                if (diff == 0) {
                    this.subscription = {
                        tooltip: `Dnes končí vaše predplatné`,
                    };
                } else {
                    this.subscription = {
                        tooltip: `Koniec predplatného ${diff > 0 ? 'o ' + diff : ''} ${day}`,
                    };
                }

                if (diff > 0 && diff <= 14) {
                    this.$q.notify({
                        message: `Vaše predplatné končí dňa ${this.organizationItem.organization.subscriptionDate.date}, čiže o ${diff} ${day}`,
                        color: 'negative',
                        position: 'top',
                        timeout: 3000,
                    });
                } else if (diff == 0) {
                    this.$q.notify({
                        message: `Dnes končí vaše predplatné!`,
                        color: 'negative',
                        position: 'top',
                        timeout: 3000,
                    });
                }
                return diff <= 14;
            }
            this.subscription = {
                tooltip: 'Nemáte zakupené predplatné',
            };
            return true;
        },
        getAppInfo() {
            return 'Verzia aplikácie: ' + (process.env.NODE_ENV == 'development' ? 'Development' : window.config.appRelease || '--');
        },
    },
    computed: {
        getNavigationItemList() {
            const navigationItems = [
                {
                    name: 'Domov',
                    icon: 'home',
                    tooltip: 'Domovská obrazovka',
                    to: '/',
                    show: true,
                },
                {
                    name: 'Nástenka',
                    icon: 'pending_actions',
                    tooltip: 'Všetky zákazky',
                    to: '/nastenka',
                    show: true,
                },
                {
                    name: 'Kalendár',
                    icon: 'calendar_month',
                    tooltip: 'Kalendár udalostí',
                    to: '/kalendar',
                    show: true,
                },
                {
                    name: 'Profil',
                    icon: 'person',
                    iconTooltip: $e.user.getUser().fullNameLogin,
                    tooltip: 'Váš profil',
                    to: '/profil',
                    show: true,
                },
                {
                    name: 'Zákazky',
                    icon: 'storage',
                    tooltip: 'Zákazky kde ste pozorovateľ alebo komisár',
                    to: '/zakazky',
                    separatorBefore: true,
                    show: !this.$e.is.empty(this.organizationItem) && this.organizationItem === -1,
                },
                {
                    name: 'VYTVORIŤ ZÁKAZKU',
                    icon: 'note_add',
                    tooltip: 'Nová zákazka',
                    routerPush: { name: 'somvyhlasovatel/zakazka/edit', params: { id: '-1' } },
                    show: this.isSetOrganization,
                    isButton: true,
                    separatorBefore: true,
                    separatorAfter: true,
                    bgColor: 'blue',
                },
                {
                    name: 'Moje zákazky',
                    icon: 'storage',
                    tooltip: 'Informácie o zákazkách',
                    to: '/mojezakazky',
                    show: this.isSetOrganization,
                    sublist: [
                        {
                            name: 'Som vyhlasovateľ',
                            tooltip: 'Kde som vyhlasovateľ',
                            to: '/mojezakazky/somvyhlasovatel',
                            show: true,
                        },
                        {
                            name: 'Som participant',
                            tooltip: 'Kde som účastník',
                            to: '/mojezakazky/somparticipant',
                            show: true,
                            badge: {
                                show: this.existOrderForApplicantRes,
                                value: '!',
                            },
                        },
                    ],
                },
                {
                    name: 'Partneri',
                    icon: 'business_center',
                    tooltip: 'Zoznam partnerov',
                    to: '/partneri',
                    show: this.isSetOrganization,
                },
                {
                    name: 'Používatelia',
                    icon: 'group',
                    tooltip: 'Zoznam používateľov',
                    to: '/pouzivatelia',
                    show: this.isSetOrganization,
                },
                {
                    name: 'Organizácia',
                    icon: 'apartment',
                    tooltip: 'Nastavenia organizácie',
                    to: '/organizacia',
                    show: this.isSetOrganization,
                },
                {
                    name: 'Predplatné',
                    icon: 'add_shopping_cart',
                    tooltip: 'Správa predplatného',
                    to: '/predplatne',
                    badge: {
                        show: this.isShowSubscription(),
                        value: '!',
                        tooltip: this.subscription.tooltip,
                    },
                    show: this.isSetOrganization,
                },
            ];
            return navigationItems;
        },
        isSetOrganization() {
            return !this.$e.is.empty(this.organizationItem) && this.organizationItem.organizationId;
        },
        organizationItem: {
            get() {
                return this.$store.state.organizationItem;
            },
            set(value) {
                this.$store.commit('setOrganizationItem', value);
            },
        },
        ...mapState(['appInfo', 'appTitle', 'appBack']),
    },
};
</script>

<style lang="scss">
.q-page-container {
    background-image: url(@/assets/vlnka.svg);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-height: 900px) {
        background-image: none;
    }
}

.hintSmall {
    max-width: 500px !important;
    .img {
        background-image: url(../assets/maskot.svg);
        background-repeat: no-repeat;
        width: 300px;
        background-size: contain;
        margin-right: 16px;
    }

    .q-notification__actions {
        .q-btn {
            min-height: 48px;
            min-width: 48px !important;
            .q-btn__content span.block {
                font-size: 1em;
                margin: 0 8px;
            }
        }
    }
}

.hintDialog {
    .q-dialog__inner > div {
        max-width: 75vw;
        @media (max-width: $breakpoint-sm-max) {
            max-width: none;
        }
    }
}

header {
    .menuToogleBtn {
        color: $dark;
    }
    .q-toolbar__title {
        font-size: em(30);
        color: $dark;
        @media (max-width: $breakpoint-sm-max) {
            font-size: em(24);
        }
        @media (max-width: $breakpoint-xs-max) {
            font-size: em(16);
        }
    }
    .floatingHeaderIcons {
        .q-fab {
            &.q-fab--opened {
                .q-btn {
                    background: $primary;
                    .q-fab__icon-holder {
                        color: white;
                    }
                }
            }
            .q-btn,
            .q-btn::before {
                border-radius: 15px;
                .q-focus-helper,
                .q-focus-helper::before,
                .q-focus-helper::after {
                    border-radius: 15px;
                }
            }
            .q-btn {
                min-width: 48px;
                min-height: 48px;
                font-size: em(14);
                padding: 8px;
                &::before {
                    @include box-shadow(0px 5px 15px 0px rgba($primary, 0.15));
                }

                .q-fab__icon-holder {
                    min-width: 32px;
                    min-height: 32px;
                    color: $primary;
                }
            }
        }

        .q-fab__actions {
            .q-btn {
                background: white !important;
                &.logout {
                    &::before {
                        @include box-shadow(0px 5px 15px 0px rgba($negative, 0.15));
                    }
                    .q-btn__content {
                        color: $negative;
                    }
                }
                .q-btn__content {
                    color: $primary;
                    span.block {
                        font-size: em(14);
                        font-weight: 600;
                        margin: 0 8px;
                    }
                    .q-badge--floating {
                        top: 3px;
                        right: 3px;
                        padding: 0;
                        width: 20px;
                        height: 20px;
                        justify-content: center;
                        justify-items: center;
                        border: 2px solid white;
                    }
                }
            }
        }
    }
    .header-icons {
        .q-btn {
            padding: 8px;
            .q-btn__content {
                .q-icon {
                    font-size: 32px;
                }
            }
            &.logout {
                &::before {
                    @include box-shadow(0px 3px 5px 0px rgba($negative, 0.15), inset 0px 0px 0px 1px rgba($negative, 0.07));
                }
            }

            .q-badge--floating {
                top: 3px;
                right: 3px;
                padding: 0;
                width: 20px;
                height: 20px;
                justify-content: center;
                justify-items: center;
                border: 2px solid white;
            }

            span.block {
                font-size: em(14);
                font-weight: 600;
                margin: 0 8px;
            }
        }
    }
}

#navPanel {
    background: rgb(50, 109, 224);
    background: linear-gradient(180deg, rgba(50, 109, 224, 1) 0%, rgba(30, 84, 185, 1) 30%, rgba(25, 79, 176, 1) 70%, rgba(29, 93, 188, 1) 100%);
    #logoPanel {
        padding: 30px 0;
        .logoImg {
            width: 12rem;
        }
    }
    .q-separator {
        background: rgba(white, 0.15);
    }

    #companyPanel {
        margin-bottom: 24px;

        .q-field--standout .q-field__control {
            background-color: rgba(lighten($primary, 60%), 0.4);
            @include border-radius(15px);
            min-height: 48px;
            padding-right: 3px;

            .q-field__append {
                height: 48px;
                padding: 0;
            }

            .q-field__native {
                color: white;
                padding-right: 9px;
                min-height: 48px;
                letter-spacing: 0.01em;
            }
            i.q-icon {
                padding: 5px;
                color: $primary;
                background: white;
                @include border-radius(12px);
            }
        }
    }

    #bottomPanel {
        font-weight: 400;

        a {
            text-decoration: none;
            color: white;
            &:hover {
                text-decoration: underline;
            }
        }
        #links2 a {
            color: rgba(white, 0.5);
        }
        #copyright {
            font-size: em(12);

            .copyrightPanel {
                position: relative;
                height: 42px;

                .q-btn {
                    position: absolute;
                    right: 0px;
                    min-width: 23px;
                    min-height: 23px;
                    width: 23px;
                    height: 23px;
                }
            }
        }
    }
}

// route transition
.route-enter-from {
    opacity: 0;
    transform: scale(0.99);
}

.route-enter-active,
.route-leave-active {
    transition: all 300ms ease;
}

.route-leave-to {
    opacity: 0;
    transform: translateX(10vw);
}
</style>
