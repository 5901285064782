import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import LoginLayout from '@/layouts/LoginLayout.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import MTView from './MTView.vue';
import RouterView from './RouterView.vue';
import $store from '@/store';

const routes = [
    {
        name: 'root',
        path: '/:pathMatch(.*)*',
        component: RouterView,
        beforeEnter(to, from) {
            return true;
        },
    },
];
const loginRoutes = [{ name: 'login', path: '/:pathMatch(.*)*', component: LoginLayout }];
const mainRoutes = [
    {
        name: 'root',
        path: '/',
        component: MainLayout,
        children: [
            {
                name: 'main',
                path: '/',
                component: RouterView,
                children: [
                    {
                        name: 'dashboard',
                        path: '',
                        component: () => import(/* webpackChunkName: "" */ '@/views/dashboard/DashboardView.vue'),
                    },
                    {
                        name: 'novaorganizacia',
                        path: '/novaorganizacia',
                        component: () => import(/* novaorganizacia: "novaorganizacia" */ '@/views/dashboard/CreateOrganizationView.vue'),
                    },
                ],
            },
            // START - Som ine
            {
                path: '/zakazky',
                meta: { role: 2 },
                component: RouterView,
                children: [
                    {
                        name: 'zakazky',
                        path: '',
                        meta: { role: 2 },
                        component: () => import(/* webpackChunkName: "zakazky" */ '@/views/zakazky/ZakazkyView.vue'),
                    },
                    {
                        name: 'zakazky/zakazka',
                        path: 'zakazka/:id',
                        meta: { role: 2 },
                        component: () => import(/* webpackChunkName: "zakazky/zakazka" */ '@/views/zakazky/zakazka/ZakazkaMainView.vue'),
                        redirect: { name: 'zakazky/zakazka/detail' },
                        children: [
                            {
                                name: 'zakazky/zakazka/detail',
                                path: 'detail',
                                meta: { role: 2 },
                                component: () => import(/* webpackChunkName: "zakazky/zakazka/detail" */ '@/views/zakazky/zakazka/DetailZakazkyView.vue'),
                            },
                            {
                                name: 'zakazky/zakazka/castizakazky',
                                path: 'castizakazky',
                                meta: { role: 2 },
                                component: () =>
                                    import(/* webpackChunkName: "zakazky/zakazka/castizakazky" */ '@/views/zakazky/zakazka/castiZakazky/ListView.vue'),
                            },
                            {
                                name: 'zakazky/zakazka/prilohyadokumenty',
                                path: 'prilohyadokumenty',
                                meta: { role: 2 },
                                component: () =>
                                    import(
                                        /* webpackChunkName: "zakazky/zakazka/prilohyadokumenty" */ '@/views/zakazky/zakazka/prilohyADokumenty/ListView.vue'
                                    ),
                            },
                            {
                                name: 'zakazky/zakazka/ucastnici',
                                path: 'ucastnici',
                                meta: { role: 2 },
                                component: RouterView,
                                children: [
                                    {
                                        path: 'osloveni',
                                        meta: { role: 2 },
                                        name: 'zakazky/zakazka/ucastnici/osloveni',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "zakazky/zakazka/ucastnici/osloveni" */ '@/views/zakazky/zakazka/ucastnici/osloveni/ListView.vue'
                                            ),
                                    },
                                    {
                                        path: 'zaujemcovia',
                                        meta: { role: 2 },
                                        name: 'zakazky/zakazka/ucastnici/zaujemcovia',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "zakazky/zakazka/ucastnici/osloveni" */ '@/views/zakazky/zakazka/ucastnici/zaujemcovia/ListView.vue'
                                            ),
                                    },
                                    {
                                        path: 'uchadzaci',
                                        meta: { role: 2 },
                                        name: 'zakazky/zakazka/ucastnici/uchadzaci',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "zakazky/zakazka/ucastnici/uchadzaci" */ '@/views/zakazky/zakazka/ucastnici/uchadzaci/ListView.vue'
                                            ),
                                    },
                                    {
                                        path: 'ponuky',
                                        meta: { role: 2 },
                                        name: 'zakazky/zakazka/ucastnici/ponuky',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "zakazky/zakazka/ucastnici/ponuky" */ '@/views/zakazky/zakazka/ucastnici/ponuky/ListView.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                path: 'komunikacia',
                                name: 'zakazky/zakazka/komunikacia',
                                meta: { role: 2 },
                                component: () =>
                                    import(/* webpackChunkName: "zakazky/zakazka/komunikacia" */ '@/views/zakazky/zakazka/komunikacia/ListView.vue'),
                            },
                            {
                                name: 'zakazky/zakazka/zaznamy',
                                path: 'zaznamy',
                                meta: { role: 2 },
                                component: () => import(/* webpackChunkName: "zakazky/zakazka/zaznamy" */ '@/views/zakazky/zakazka/zaznamy/ListView.vue'),
                            },
                        ],
                    },
                    {
                        name: 'zakazky/zakazka/castizakazky/cast',
                        path: 'zakazka/:id/castizakazky/cast/:partid',
                        meta: { role: 2 },
                        component: () =>
                            import(/* webpackChunkName: "zakazky/zakazka/castizakazky/cast" */ '@/views/zakazky/zakazka/castiZakazky/DetailView.vue'),
                    },
                    {
                        name: 'zakazky/zakazka/prilohy/priloha',
                        path: 'zakazka/:id/prilohy/priloha/:docid',
                        meta: { role: 2 },
                        component: () =>
                            import(/* webpackChunkName: "zakazky/zakazka/prilohy/priloha" */ '@/views/zakazky/zakazka/prilohyADokumenty/DetailView.vue'),
                    },
                    {
                        name: 'zakazky/zakazka/komunikacia/sprava',
                        path: 'zakazka/:id/komunikacia/sprava/:msgid',
                        meta: { role: 2 },
                        component: () =>
                            import(/* webpackChunkName: "zakazky/zakazka/komunikacia/sprava" */ '@/views/zakazky/zakazka/komunikacia/MessageDetail.vue'),
                    },
                ],
            },
            // END - Som ine
            {
                name: 'mojezakazky',
                path: '/mojezakazky',
                meta: { role: 1 },
                component: () => import(/* webpackChunkName: "mojezakazky" */ '@/views/mojeZakazky/MojeZakazkyView.vue'),
            },
            // START - Som vyhlasovatel
            {
                path: '/mojezakazky/somvyhlasovatel',
                meta: { role: 1 },
                component: RouterView,
                children: [
                    {
                        name: 'mojezakazky/somvyhlasovatel',
                        path: '',
                        meta: { role: 1 },
                        component: () =>
                            import(/* webpackChunkName: "mojezakazky/somvyhlasovatel" */ '@/views/mojeZakazky/somVyhlasovatel/SomVyhlasovatelView.vue'),
                    },
                    {
                        name: 'somvyhlasovatel/zakazka',
                        path: 'zakazka/:id',
                        meta: { role: 1 },
                        component: () =>
                            import(/* webpackChunkName: "somvyhlasovatel/zakazka" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/ZakazkaMainView.vue'),
                        redirect: { name: 'somvyhlasovatel/zakazka/detail' },
                        children: [
                            {
                                name: 'somvyhlasovatel/zakazka/detail',
                                path: 'detail',
                                meta: { role: 1 },
                                component: () =>
                                    import(
                                        /* webpackChunkName: "somvyhlasovatel/zakazka/detail" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/DetailZakazkyView.vue'
                                    ),
                            },
                            {
                                name: 'somvyhlasovatel/zakazka/castizakazky',
                                path: 'castizakazky',
                                meta: { role: 1 },
                                component: () =>
                                    import(
                                        /* webpackChunkName: "somvyhlasovatel/zakazka/castizakazky" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/castiZakazky/ListView.vue'
                                    ),
                            },
                            {
                                name: 'somvyhlasovatel/zakazka/prilohyadokumenty',
                                path: 'prilohyadokumenty',
                                meta: { role: 1 },
                                component: () =>
                                    import(
                                        /* webpackChunkName: "somvyhlasovatel/zakazka/prilohyadokumenty" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/prilohyADokumenty/ListView.vue'
                                    ),
                            },
                            {
                                name: 'somvyhlasovatel/zakazka/komisia',
                                path: 'komisia',
                                meta: { role: 1 },
                                component: () =>
                                    import(
                                        /* webpackChunkName: "somvyhlasovatel/zakazka/komisia" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/komisia/ListView.vue'
                                    ),
                            },
                            {
                                name: 'somvyhlasovatel/zakazka/ucastnici',
                                path: 'ucastnici',
                                meta: { role: 1 },
                                component: RouterView,
                                children: [
                                    {
                                        path: 'osloveni',
                                        meta: { role: 1 },
                                        name: 'somvyhlasovatel/zakazka/ucastnici/osloveni',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "somvyhlasovatel/zakazka/ucastnici/osloveni" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/ucastnici/osloveni/ListView.vue'
                                            ),
                                    },
                                    {
                                        path: 'zaujemcovia',
                                        meta: { role: 1 },
                                        name: 'somvyhlasovatel/zakazka/ucastnici/zaujemcovia',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "somvyhlasovatel/zakazka/ucastnici/osloveni" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/ucastnici/zaujemcovia/ListView.vue'
                                            ),
                                    },
                                    {
                                        path: 'uchadzaci',
                                        meta: { role: 1 },
                                        name: 'somvyhlasovatel/zakazka/ucastnici/uchadzaci',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "somvyhlasovatel/zakazka/ucastnici/uchadzaci" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/ucastnici/uchadzaci/ListView.vue'
                                            ),
                                    },
                                    {
                                        path: 'ponuky',
                                        meta: { role: 1 },
                                        name: 'somvyhlasovatel/zakazka/ucastnici/ponuky',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "somvyhlasovatel/zakazka/ucastnici/ponuky" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/ucastnici/ponuky/ListView.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                name: 'somvyhlasovatel/zakazka/komunikacia',
                                path: 'komunikacia',
                                meta: { role: 1 },
                                component: RouterView,
                                // redirect: { name: 'somvyhlasovatel/zakazka/komunikacia/prijate' },
                                children: [
                                    {
                                        path: 'prijate',
                                        meta: { role: 1 },
                                        name: 'somvyhlasovatel/zakazka/komunikacia/prijate',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "somvyhlasovatel/zakazka/komunikacia/prijate" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/komunikacia/ListView.vue'
                                            ),
                                    },
                                    {
                                        path: 'odoslane',
                                        meta: { role: 1 },
                                        name: 'somvyhlasovatel/zakazka/komunikacia/odoslane',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "somvyhlasovatel/zakazka/komunikacia/odoslane" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/komunikacia/ListView.vue'
                                            ),
                                    },
                                    {
                                        path: 'koncept',
                                        meta: { role: 1 },
                                        name: 'somvyhlasovatel/zakazka/komunikacia/koncept',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "somvyhlasovatel/zakazka/komunikacia/koncept" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/komunikacia/ListView.vue'
                                            ),
                                    },
                                    {
                                        path: 'zmazane',
                                        meta: { role: 1 },
                                        name: 'somvyhlasovatel/zakazka/komunikacia/zmazane',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "somvyhlasovatel/zakazka/komunikacia/zmazane" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/komunikacia/ListView.vue'
                                            ),
                                    },
                                ],
                            },
                            {
                                name: 'somvyhlasovatel/zakazka/otazky',
                                path: 'otazky',
                                meta: { role: 1 },
                                component: () =>
                                    import(
                                        /* webpackChunkName: "somvyhlasovatel/zakazka/otazky" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/otazky/ListView.vue'
                                    ),
                            },
                            {
                                name: 'somvyhlasovatel/zakazka/spravcovia',
                                path: 'spravcovia',
                                meta: { role: 1 },
                                component: () =>
                                    import(
                                        /* webpackChunkName: "somvyhlasovatel/zakazka/spravcovia" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/spravcovia/ListView.vue'
                                    ),
                            },
                            {
                                name: 'somvyhlasovatel/zakazka/zaznamy',
                                path: 'zaznamy',
                                meta: { role: 1 },
                                component: () =>
                                    import(
                                        /* webpackChunkName: "somvyhlasovatel/zakazka/zaznamy" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/zaznamy/ListView.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        name: 'somvyhlasovatel/zakazka/edit',
                        path: 'zakazka/:id/edit',
                        meta: { role: 1 },
                        component: () =>
                            import(/* webpackChunkName: "somvyhlasovatel/zakazka/edit" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/EditZakazkyView.vue'),
                    },
                    {
                        name: 'somvyhlasovatel/zakazka/castizakazky/cast',
                        path: 'zakazka/:id/castizakazky/cast/:partid',
                        meta: { role: 1 },
                        component: () =>
                            import(
                                /* webpackChunkName: "somvyhlasovatel/zakazka/castizakazky/detail" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/castiZakazky/DetailView.vue'
                            ),
                    },
                    {
                        name: 'somvyhlasovatel/zakazka/prilohy/priloha',
                        path: 'zakazka/:id/prilohy/priloha/:docid',
                        meta: { role: 1 },
                        component: () =>
                            import(
                                /* webpackChunkName: "somvyhlasovatel/zakazka/prilohy/priloha" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/prilohyADokumenty/DetailView.vue'
                            ),
                    },
                    {
                        name: 'somvyhlasovatel/zakazka/komisia/clen',
                        path: 'zakazka/:id/komisia/clen/:comid',
                        meta: { role: 1 },
                        component: () =>
                            import(
                                /* webpackChunkName: "somvyhlasovatel/zakazka/komisia/clen" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/komisia/DetailView.vue'
                            ),
                    },
                    {
                        name: 'somvyhlasovatel/zakazka/otazky/otazka',
                        path: 'zakazka/:id/otazky/otazka/:quesid',
                        meta: { role: 1 },
                        component: () =>
                            import(
                                /* webpackChunkName: "somvyhlasovatel/zakazka/otazky/otazka" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/otazky/DetailView.vue'
                            ),
                    },
                    {
                        name: 'somvyhlasovatel/zakazka/komunikacia/sprava',
                        path: 'zakazka/:id/komunikacia/sprava/:msgid/:respid?',
                        meta: { role: 1 },
                        component: () =>
                            import(
                                /* webpackChunkName: "somvyhlasovatel/zakazka/komunikacia/sprava" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/komunikacia/MessageEdit.vue'
                            ),
                    },
                    {
                        name: 'somvyhlasovatel/zakazka/komunikacia/detail',
                        path: 'zakazka/:id/komunikacia/sprava/detail/:msgid',
                        meta: { role: 1 },
                        component: () =>
                            import(
                                /* webpackChunkName: "somvyhlasovatel/zakazka/komunikacia/detail" */ '@/views/mojeZakazky/somVyhlasovatel/zakazka/komunikacia/MessageDetail.vue'
                            ),
                    },
                ],
            },
            // END - Som vyhlasovatel

            // START - Som participant
            {
                path: '/mojezakazky/somparticipant',
                meta: { role: 1 },
                component: RouterView,
                children: [
                    {
                        name: 'mojezakazky/somparticipant',
                        path: '',
                        meta: { role: 1 },
                        component: () =>
                            import(/* webpackChunkName: "mojezakazky/somparticipant" */ '@/views/mojeZakazky/somParticipant/SomParticipantView.vue'),
                    },
                    {
                        name: 'somparticipant/zakazka',
                        path: 'zakazka/:id',
                        meta: { role: 1 },
                        component: () =>
                            import(/* webpackChunkName: "somparticipant/zakazka" */ '@/views/mojeZakazky/somParticipant/zakazka/ZakazkaMainView.vue'),
                        redirect: { name: 'somparticipant/zakazka/detail' },
                        children: [
                            {
                                name: 'somparticipant/zakazka/detail',
                                path: 'detail',
                                meta: { role: 1 },
                                component: () =>
                                    import(/* webpackChunkName: "somparticipant/zakazka" */ '@/views/mojeZakazky/somParticipant/zakazka/DetailZakazkyView.vue'),
                            },
                            {
                                name: 'somparticipant/zakazka/castizakazky',
                                path: 'castizakazky',
                                meta: { role: 1 },
                                component: () =>
                                    import(
                                        /* webpackChunkName: "somparticipant/zakazka/castizakazky" */ '@/views/mojeZakazky/somParticipant/zakazka/castiZakazky/ListView.vue'
                                    ),
                            },
                            {
                                name: 'somparticipant/zakazka/prilohyadokumenty',
                                path: 'prilohyadokumenty',
                                meta: { role: 1 },
                                component: () =>
                                    import(
                                        /* webpackChunkName: "somparticipant/zakazka/prilohyadokumenty" */ '@/views/mojeZakazky/somParticipant/zakazka/prilohyADokumenty/ListView.vue'
                                    ),
                            },
                            {
                                name: 'somparticipant/zakazka/ponuky',
                                path: 'ponuky',
                                meta: { role: 1 },
                                component: () =>
                                    import(
                                        /* webpackChunkName: "somparticipant/zakazka/ponuky" */ '@/views/mojeZakazky/somParticipant/zakazka/ponuky/ListView.vue'
                                    ),
                            },
                            {
                                name: 'somparticipant/zakazka/otazky',
                                path: 'otazky',
                                meta: { role: 1 },
                                component: () =>
                                    import(
                                        /* webpackChunkName: "somparticipant/zakazka/otazky" */ '@/views/mojeZakazky/somParticipant/zakazka/otazky/ListView.vue'
                                    ),
                            },
                            {
                                name: 'somparticipant/zakazka/spravcovia',
                                path: 'spravcovia',
                                meta: { role: 1 },
                                component: () =>
                                    import(
                                        /* webpackChunkName: "somparticipant/zakazka/otazky" */ '@/views/mojeZakazky/somParticipant/zakazka/spravcovia/ListView.vue'
                                    ),
                            },
                            {
                                name: 'somparticipant/zakazka/komunikacia',
                                path: 'komunikacia',
                                meta: { role: 1 },
                                component: RouterView,
                                children: [
                                    {
                                        path: 'prijate',
                                        meta: { role: 1 },
                                        name: 'somparticipant/zakazka/komunikacia/prijate',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "somparticipant/zakazka/komunikacia/prijate" */ '@/views/mojeZakazky/somParticipant/zakazka/komunikacia/ListView.vue'
                                            ),
                                    },
                                    {
                                        path: 'odoslane',
                                        meta: { role: 1 },
                                        name: 'somparticipant/zakazka/komunikacia/odoslane',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "somparticipant/zakazka/komunikacia/odoslane" */ '@/views/mojeZakazky/somParticipant/zakazka/komunikacia/ListView.vue'
                                            ),
                                    },
                                    {
                                        path: 'koncept',
                                        meta: { role: 1 },
                                        name: 'somparticipant/zakazka/komunikacia/koncept',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "somparticipant/zakazka/komunikacia/koncept" */ '@/views/mojeZakazky/somParticipant/zakazka/komunikacia/ListView.vue'
                                            ),
                                    },
                                    {
                                        path: 'zmazane',
                                        meta: { role: 1 },
                                        name: 'somparticipant/zakazka/komunikacia/zmazane',
                                        component: () =>
                                            import(
                                                /* webpackChunkName: "somparticipant/zakazka/komunikacia/zmazane" */ '@/views/mojeZakazky/somParticipant/zakazka/komunikacia/ListView.vue'
                                            ),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: 'somparticipant/zakazka/castizakazky/cast',
                        path: 'zakazka/:id/castizakazky/cast/:partid',
                        meta: { role: 1 },
                        component: () =>
                            import(
                                /* webpackChunkName: "somparticipant/zakazka/castizakazky/detail" */ '@/views/mojeZakazky/somParticipant/zakazka/castiZakazky/DetailView.vue'
                            ),
                    },
                    {
                        name: 'somparticipant/zakazka/prilohy/priloha',
                        path: 'zakazka/:id/prilohy/priloha/:docid',
                        meta: { role: 1 },
                        component: () =>
                            import(
                                /* webpackChunkName: "somparticipant/zakazka/prilohyadokumenty/priloha" */ '@/views/mojeZakazky/somParticipant/zakazka/prilohyADokumenty/DetailView.vue'
                            ),
                    },
                    {
                        name: 'somparticipant/zakazka/otazky/otazka',
                        path: 'zakazka/:id/otazky/otazka/:questid',
                        meta: { role: 1 },
                        component: () =>
                            import(
                                /* webpackChunkName: "somparticipant/zakazka/otazky/otazka" */ '@/views/mojeZakazky/somParticipant/zakazka/otazky/DetailView.vue'
                            ),
                    },
                    {
                        name: 'somparticipant/zakazka/komunikacia/sprava',
                        path: 'zakazka/:id/komunikacia/sprava/:msgid/:respid?',
                        meta: { role: 1 },
                        component: () =>
                            import(
                                /* webpackChunkName: "somparticipant/zakazka/komunikacia/sprava" */ '@/views/mojeZakazky/somParticipant/zakazka/komunikacia/MessageEdit.vue'
                            ),
                    },
                    {
                        name: 'somparticipant/zakazka/komunikacia/detail',
                        path: 'zakazka/:id/komunikacia/sprava/detail/:msgid',
                        meta: { role: 1 },
                        component: () =>
                            import(
                                /* webpackChunkName: "somparticipant/zakazka/komunikacia/detail" */ '@/views/mojeZakazky/somParticipant/zakazka/komunikacia/MessageDetail.vue'
                            ),
                    },
                ],
            },
            // END - Som participant

            // START - Som public
            {
                path: '/nastenka',
                component: RouterView,
                children: [
                    {
                        name: 'nastenka',
                        path: '',
                        component: () => import(/* webpackChunkName: "nastenka" */ '@/views/NastenkaView.vue'),
                    },
                    {
                        name: 'sompublic/zakazka',
                        path: 'zakazka/:id',
                        component: () => import(/* webpackChunkName: "somparticipant/zakazka" */ '@/views/mojeZakazky/somPublic/zakazka/ZakazkaMainView.vue'),
                        redirect: { name: 'somparticipant/zakazka/detail' },
                        children: [
                            {
                                name: 'sompublic/zakazka/detail',
                                path: 'detail',
                                component: () =>
                                    import(/* webpackChunkName: "somparticipant/zakazka" */ '@/views/mojeZakazky/somPublic/zakazka/DetailZakazkyView.vue'),
                            },
                            {
                                name: 'sompublic/zakazka/castizakazky',
                                path: 'castizakazky',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "somparticipant/zakazka/castizakazky" */ '@/views/mojeZakazky/somPublic/zakazka/castiZakazky/ListView.vue'
                                    ),
                            },
                            {
                                name: 'sompublic/zakazka/prilohyadokumenty',
                                path: 'prilohyadokumenty',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "somparticipant/zakazka/prilohyadokumenty" */ '@/views/mojeZakazky/somPublic/zakazka/prilohyADokumenty/ListView.vue'
                                    ),
                            },
                        ],
                    },
                    {
                        name: 'sompublic/zakazka/castizakazky/cast',
                        path: 'zakazka/:id/castizakazky/cast/:partid',
                        component: () =>
                            import(
                                /* webpackChunkName: "somparticipant/zakazka/castizakazky/detail" */ '@/views/mojeZakazky/somPublic/zakazka/castiZakazky/DetailView.vue'
                            ),
                    },
                    {
                        name: 'sompublic/zakazka/prilohy/priloha',
                        path: 'zakazka/:id/prilohy/priloha/:docid',
                        component: () =>
                            import(
                                /* webpackChunkName: "somparticipant/zakazka/prilohyadokumenty/priloha" */ '@/views/mojeZakazky/somPublic/zakazka/prilohyADokumenty/DetailView.vue'
                            ),
                    },
                ],
            },
            // END - Som public
            {
                path: '/partneri',
                meta: { role: 1 },
                component: RouterView,
                children: [
                    {
                        name: 'partneri',
                        path: '',
                        meta: { role: 1 },
                        component: () => import(/* webpackChunkName: "partneri" */ '@/views/partneri/ListView.vue'),
                    },
                    {
                        name: 'partneri/partner',
                        path: 'partner/:id/:orderid?',
                        meta: { role: 1 },
                        component: () => import(/* webpackChunkName: "partneri/detail" */ '@/views/partneri/DetailView.vue'),
                    },
                ],
            },
            {
                name: 'kalendar',
                path: '/kalendar',
                component: () => import(/* webpackChunkName: "kalendar" */ '../views/CalendarView.vue'),
            },
            {
                path: '/pouzivatelia',
                meta: { role: 1 },
                component: RouterView,
                children: [
                    {
                        name: 'pouzivatelia',
                        path: '',
                        meta: { role: 1 },
                        component: () => import(/* webpackChunkName: "pouzivatelia" */ '@/views/pouzivatelia/ListView.vue'),
                    },
                    {
                        name: 'pouzivatel',
                        path: 'pouzivatel/:id',
                        meta: { role: 1 },
                        component: () => import(/* webpackChunkName: "pouzivatelia/detail" */ '@/views/pouzivatelia/DetailView.vue'),
                    },
                ],
            },
            {
                name: 'organizacia',
                path: '/organizacia',
                meta: { role: 1 },
                component: () => import(/* webpackChunkName: "organizacia" */ '@/views/OrganizationView.vue'),
            },
            {
                name: 'predplatne',
                path: '/predplatne',
                meta: { role: 1 },
                component: () => import(/* webpackChunkName: "predplatne" */ '@/views/PredplatneView.vue'),
            },
            {
                name: 'nastavenia',
                path: '/nastavenia',
                meta: { role: 1 },
                component: MTView,
            },
            {
                name: 'profil',
                path: '/profil',
                component: () => import(/* webpackChunkName: "profil" */ '@/views/ProfilView.vue'),
            },
            {
                name: 'kontakt',
                path: '/kontakt',
                component: () => import(/* webpackChunkName: "kontakt" */ '@/views/KontaktView.vue'),
            },
        ],
    },
    {
        name: 'zakazka',
        path: '/zakazka/:id',
        component: () => import(/* webpackChunkName: "zakazka" */ '@/views/ZakazkaEnterFromWeb.vue'),
    },
    { name: 'catch', path: '/:catchAll(.*)*', redirect: '/' },
];

const router = createRouter({
    history:
        process.env.VUE_APP_ROUTER_MODE && process.env.VUE_APP_ROUTER_MODE == 'hash'
            ? createWebHashHistory(process.env.BASE_URL)
            : createWebHistory(process.env.BASE_URL),
    routes,
});

router.addRoutes = function (routes) {
    router.options.routes = routes;
    routes.forEach((route) => {
        router.addRoute(route);
    });
};
router.removeRoutes = function () {
    router.options.routes.forEach((route) => {
        router.removeRoute(route.name);
    });
    router.options.routes = [];
};
router.reload = function (baseUrl) {
    const path = baseUrl ? '/' : window.location.href.replace(window.location.origin + router.options.history.base, '');
    router.replace(router.resolve(path));
};

router.login = function () {
    router.removeRoutes();
    router.addRoutes(mainRoutes);
    router.reload(false);
};
router.logout = function (baseUrl) {
    router.removeRoutes();
    router.addRoutes(loginRoutes);
    router.reload(false);
};

router.beforeEach((to, from, next) => {
    if (to.meta.role) {
        let success = false;
        const org = $store.state.organizationItem;
        if (org == -1 && to.meta.role == 2) {
            // Ine
            success = true;
        } else if (org?.organizationId && to.meta.role == 1) {
            // Vyhlasovatel / Particpant
            success = true;
        } else if (to.meta.role == 0) {
            // bez organizacie
            success = true;
        }

        if (success) {
            next();
        } else {
            next({ path: '/' });
        }
    } else {
        next();
    }
});

export default router;
